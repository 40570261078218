import Vue from 'vue'
import VueRouter from 'vue-router'
import { isUserLoggedIn } from '@/auth/utils'

import hotel from "./routes/hotel";
import refund from "./routes/refund";
import reports from "./routes/reports";
import amenity from "./routes/amenity";
import themes from './routes/themes';
import propertyTypes from './routes/propertyTypes';
import attractions from './routes/attractions';
import experience from './routes/experience';
import thingsToDo from './routes/thingsToDo';
import gallery from './routes/gallery';
import pages from './routes/pages';
import tender from './routes/tender';
import team from './routes/team';
import newsEvents from './routes/news-events';
import resortManager from './routes/resort-manager';
import user from './routes/user';
import regionalOffice from './routes/regional-office';
import bookingCounter from './routes/booking-counter';

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
        redirectIfLoggedIn: true
      },
    },
    ...hotel,
    ...amenity,
    ...themes,
    ...propertyTypes,
    ...attractions,
    ...experience,
    ...thingsToDo,
    ...gallery,
    ...newsEvents,
    ...resortManager,
    ...user,
    ...regionalOffice,
    ...bookingCounter,
    ...tender,
    ...pages,
    ...team,
    ...refund,
    ...reports,
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn();
  if (to.name !== 'login' && !isLoggedIn) {
    next({ name: 'login' });
  } else if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next("/")
  } else {
    next()
  }
})


// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
