export default [
    {
        path: '/events/add',
        name: 'events-add',
        component: () => import('@/views/Events/EventsAdd.vue'),
        meta: {
            pageTitle: 'Add Event',
            breadcrumb: [
                {
                    text: 'All Events',
                    to: "/events/list"
                },
                {
                    text: 'Add Event',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/events/list',
        name: 'events-list',
        component: () => import('@/views/Events/EventsList.vue'),
        meta: {
            pageTitle: 'All Events',
            breadcrumb: [
                {
                    text: 'Events List',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/events/:eventID',
        name: 'event-update',
        component: () => import('@/views/Events/EventsUpdate.vue'),
        meta: {
            pageTitle: 'Update Event',
            breadcrumb: [
                {
                    text: 'All Events',
                    to: "/events/list"
                },
                {
                    text: 'Update List',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/news/add',
        name: 'news-add',
        component: () => import('@/views/News/NewsAdd.vue'),
        meta: {
            pageTitle: 'Add News',
            breadcrumb: [
                {
                    text: 'All News',
                    to: "/news/list"
                },
                {
                    text: 'Add Event',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/news/list',
        name: 'news-list',
        component: () => import('@/views/News/NewsList.vue'),
        meta: {
            pageTitle: 'All News',
            breadcrumb: [
                {
                    text: 'News List',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/news/:slug',
        name: 'news-update',
        component: () => import('@/views/News/NewsUpdate.vue'),
        meta: {
            pageTitle: 'Update News',
            breadcrumb: [
                {
                    text: 'News',
                    active: true,
                },
            ],
        },
    },
]