export default [
    {
        path: '/explore-maharashtra/add',
        name: 'explore-maharashtra-add',
        component: () => import('@/views/Experiences/ExperiencesAdd.vue'),
        meta: {
            pageTitle: 'Add Experience',
            breadcrumb: [
                {
                    text: 'Explore MH List',
                    to: "/explore-maharashtra/list"
                },
                {
                    text: 'Add Experience',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/explore-maharashtra/list',
        name: 'explore-maharashtra-list',
        component: () => import('@/views/Experiences/ExperiencesList.vue'),
        meta: {
            pageTitle: 'Explore MH listing',
            breadcrumb: [
                {
                    text: 'Explore MH List',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/explore-maharashtra/:slug',
        name: 'explore-maharashtra-update',
        component: () => import('@/views/Experiences/ExperienceUpdate.vue'),
        meta: {
            pageTitle: 'Experience',
            breadcrumb: [
                {
                    text: 'Explore MH List',
                    to: "/explore-maharashtra/list"
                },
                {
                    text: 'Experience Details',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/explore-maharashtra/explore-item/list',
        name: 'explore-item-list',
        component: () => import('@/views/Experiences/ExperienceItems/ExperienceItemList.vue'),
        meta: {
            pageTitle: 'Experience Items',
            breadcrumb: [
                {
                    text: 'Experience Items',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/explore-maharashtra/explore-item/add',
        name: 'explore-item-add',
        component: () => import('@/views/Experiences/ExperienceItems/ExperienceItemAdd.vue'),
        meta: {
            pageTitle: 'Add Experience Items',
            breadcrumb: [
                {
                    text: 'Add Experience Items',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/experience/experience-item/:slug',
        name: 'experience-item-update',
        component: () => import('@/views/Experiences/ExperienceItems/ExperienceItemUpdate.vue'),
        meta: {
            pageTitle: 'Add Experience Items',
            breadcrumb: [
                {
                    text: 'Add Experience Items',
                    active: true,
                },
            ],
        },
    },
]