export default [
    {
        path: '/booking-counter/add',
        name: 'booking-counter-add',
        component: () => import('@/views/BookingCounters/BookingCountersAdd.vue'),
        meta: {
            pageTitle: 'New Booking Counter',
            breadcrumb: [
                {
                    text: 'Booking Counters',
                    to: "/booking-counter/list"
                },
                {
                    text: 'New Booking Counter',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/booking-counter/get/:slug',
        name: 'booking-counter-update',
        component: () => import('@/views/BookingCounters/BookingCountersUpdate.vue'),
        meta: {
            pageTitle: 'Update Booking Counter',
            breadcrumb: [
                {
                    text: 'Booking Counters',
                    to: "/booking-counter/list"
                },
                {
                    text: 'Update Booking Counter',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/booking-counter/list',
        name: 'booking-counter-list',
        component: () => import('@/views/BookingCounters/BookingCountersList.vue'),
        meta: {
            pageTitle: 'Booking Counters',
            breadcrumb: [
                {
                    text: 'Booking Counters',
                    active: true,
                },
            ],
        },
    },
]