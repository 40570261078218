export default [{
    path: '/things-to-do/add',
    name: 'things-to-do-add',
    component: () => import('@/views/ThingsToDo/ThingsToDoAdd.vue'),
    meta: {
        pageTitle: 'Add Things to do',
        breadcrumb: [
            {
                text: 'Add Things to do',
                active: true,
            },
        ],
    },
},
{
    path: '/things-to-do/list',
    name: 'things-to-do-list',
    component: () => import('@/views/ThingsToDo/ThingsToDoList.vue'),
    meta: {
        pageTitle: 'Things to do',
        breadcrumb: [
            {
                text: 'Things to do',
                active: true,
            },
        ],
    },
},
{
    path: '/things-to-do/get/:slug',
    name: 'things-to-do-update',
    component: () => import('@/views/ThingsToDo/ThingsToDoUpdate.vue'),
    meta: {
        pageTitle: 'Things to do',
        breadcrumb: [
            {
                text: 'Things to do',
                active: true,
            },
        ],
    },
},]