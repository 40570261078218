export default [
    {
        path: '/resort-manager/add',
        name: 'resort-manager-add',
        component: () => import('@/views/ResortManagers/ResortManagerAdd.vue'),
        meta: {
            pageTitle: 'Add Resort Manager',
            breadcrumb: [
                {
                    text: 'Resort Managers',
                    to: "/resort-manager/list"
                },
                {
                    text: 'Add Resort Manager',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/resort-manager/get/:slug',
        name: 'resort-manager-update',
        component: () => import('@/views/ResortManagers/ResortManagerUpdate.vue'),
        meta: {
            pageTitle: 'Update Resort Manager',
            breadcrumb: [
                {
                    text: 'Resort Managers',
                    to: "/resort-manager/list"
                },
                {
                    text: 'Update Resort Manager',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/resort-manager/list',
        name: 'resort-manager-list',
        component: () => import('@/views/ResortManagers/ResortManagerList.vue'),
        meta: {
            pageTitle: 'Resort Managers',
            breadcrumb: [
                {
                    text: 'Resort Managers',
                    active: true,
                },
            ],
        },
    },
]