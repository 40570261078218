export default [
    {
        path: '/user/add',
        name: 'user-add',
        component: () => import('@/views/Users/UsersAdd.vue'),
        meta: {
            pageTitle: 'Add User',
            breadcrumb: [
                {
                    text: 'Users',
                    to: "/user/list"
                },
                {
                    text: 'Add User',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/user/update/:slug',
        name: 'user-update',
        component: () => import('@/views/Users/UsersUpdate.vue'),
        meta: {
            pageTitle: 'Update User',
            breadcrumb: [
                {
                    text: 'Users',
                    to: "/user/list"
                },
                {
                    text: 'Update User',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/user/list',
        name: 'user-list',
        component: () => import('@/views/Users/UsersList.vue'),
        meta: {
            pageTitle: 'Users',
            breadcrumb: [
                {
                    text: 'Users',
                    active: true,
                },
            ],
        },
    },
]