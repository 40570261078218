export default [
    {
        path: '/regional-office/add',
        name: 'regional-office-add',
        component: () => import('@/views/RegionalOffice/RegionalOfficeAdd.vue'),
        meta: {
            pageTitle: 'New Regional Office',
            breadcrumb: [
                {
                    text: 'Regional Offices',
                    to: "/regional-office/list"
                },
                {
                    text: 'New Regional Office',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/regional-office/get/:slug',
        name: 'regional-office-update',
        component: () => import('@/views/RegionalOffice/RegionalOfficeUpdate.vue'),
        meta: {
            pageTitle: 'Update Regional Office',
            breadcrumb: [
                {
                    text: 'Regional Offices',
                    to: "/regional-office/list"
                },
                {
                    text: 'Update Regional Office',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/regional-office/list',
        name: 'regional-office-list',
        component: () => import('@/views/RegionalOffice/RegionalOfficeList.vue'),
        meta: {
            pageTitle: 'Regional Offices',
            breadcrumb: [
                {
                    text: 'Regional Offices',
                    active: true,
                },
            ],
        },
    },
]