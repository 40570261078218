export default [
    {
        path: '/team/add',
        name: 'team-add',
        component: () => import('@/views/Team/AddTeam.vue'),
        meta: {
            pageTitle: 'Add Team',
            breadcrumb: [
                {
                    text: 'Add Team',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/team/list',
        name: 'team-list',
        component: () => import('@/views/Team/TeamLists.vue'),
        meta: {
            pageTitle: 'Team',
            breadcrumb: [
                {
                    text: 'Team',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/team/:slug',
        name: 'team-update',
        component: () => import('@/views/Team/UpdateTeam.vue'),
        meta: {
            pageTitle: 'Team',
            breadcrumb: [
                {
                    text: 'Team',
                    active: true,
                },
            ],
        },
    },
]
