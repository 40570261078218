export default [
    {
        path: '/page/about-us',
        name: 'about-us-page',
        component: () => import('@/views/Pages/AboutUs/About.vue'),
        meta: {
            pageTitle: 'About Us',
            breadcrumb: [
                {
                    text: 'About Us',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/page/about-us/rti',
        name: 'rti-page',
        component: () => import('@/views/Pages/AboutUs/RTI.vue'),
        meta: {
            pageTitle: 'RTI',
            breadcrumb: [
                {
                    text: 'About Us',
                    active: false,
                },
                {
                    text: 'RTI',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/page/about-us/organization-structure',
        name: 'about-us-org-structure',
        component: () => import('@/views/Pages/AboutUs/OrganizationStructure.vue'),
        meta: {
            pageTitle: 'Organization Structure',
            breadcrumb: [
                {
                    text: 'About Us',
                    active: false,
                },
                {
                    text: 'Organization Structure',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/page/home',
        name: 'home-page',
        component: () => import('@/views/Pages/Home.vue'),
        meta: {
            pageTitle: 'Home Page',
            breadcrumb: [
                {
                    text: 'Home',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/page/covid-19',
        name: 'covid-page',
        component: () => import('@/views/Pages/Covid19.vue'),
        meta: {
            pageTitle: 'Covid 19',
            breadcrumb: [
                {
                    text: 'Home',
                    to: "/"
                },
                {
                    text: 'Covid 19',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/page/cancellation-policy',
        name: 'cancellation-page',
        component: () => import('@/views/Pages/CancellationPolicy.vue'),
        meta: {
            pageTitle: 'Cancellation Policy',
            breadcrumb: [
                {
                    text: 'Home',
                    to: "/"
                },
                {
                    text: 'Cancellation Policy',
                    active: true,
                },
            ],
        },
    },
    
    {
        path: '/page/privacy-policy',
        name: 'privacy-page',
        component: () => import('@/views/Pages/PrivacyPolicy.vue'),
        meta: {
            pageTitle: 'Privacy Policy',
            breadcrumb: [
                {
                    text: 'Home',
                    to: "/"
                },
                {
                    text: 'Privacy Policy',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/page/TnC',
        name: 'terms-page',
        component: () => import('@/views/Pages/TermsNCondition.vue'),
        meta: {
            pageTitle: 'Terms & Condition',
            breadcrumb: [
                {
                    text: 'Home',
                    to: "/"
                },
                {
                    text: 'TnC',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/page/tourism',
        name: 'tourism-page',
        component: () => import('@/views/Pages/Tourism.vue'),
        meta: {
            pageTitle: 'Tourism Page',
            breadcrumb: [
                {
                    text: 'Home',
                    to: "/"
                },
                {
                    text: 'Tourism Page',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/page/GR',
        name: 'GR-page',
        component: () => import('@/views/Pages/GR.vue'),
        meta: {
            pageTitle: 'Government Resolution Page',
            breadcrumb: [
                {
                    text: 'Home',
                    to: "/"
                },
                {
                    text: 'Government Resolution Page',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/pages/media-gallery',
        name: 'media-gallery-page',
        component: () => import('@/views/Gallery/MediaGallery.vue'),
        meta: {
            pageTitle: 'Media Gallery Page',
            breadcrumb: [
                {
                    text: 'Media Gallery',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/gallery-item/add',
        name: 'gallery-item-add',
        component: () => import('@/views/Gallery/GalleryItems/GalleryItemAdd.vue'),
        meta: {
            pageTitle: 'Gallery Item',
            breadcrumb: [
                {
                    text: 'Add Gallery',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/gallery-item/list',
        name: 'gallery-list',
        component: () => import('@/views/Gallery/GalleryItems/GalleryItemList.vue'),
        meta: {
            pageTitle: 'Gallery Item List',
            breadcrumb: [
                {
                    text: 'View All',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/gallery/:slug',
        name: 'gallery-update',
        component: () => import('@/views/Gallery/GalleryItems/GalleryItemUpdate.vue'),
        meta: {
            pageTitle: 'Gallery Item',
            breadcrumb: [
                {
                    text: 'Update Gallery Item',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/concession/add',
        name: 'concession-add',
        component: () => import('@/views/ConcessionDocument/Concessionadd.vue'),
        meta: {
            pageTitle: 'Add concession Document',
            breadcrumb: [
                {
                    text: 'Concession Document',
                    to: "/concession/list"
                },
                {
                    text: 'Add',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/concession/get/:slug',
        name: 'concession-update',
        component: () => import('@/views/ConcessionDocument/Concessionupdate.vue'),
        meta: {
            pageTitle: 'Update concession Document',
            breadcrumb: [
                {
                    text: 'Concession Document',
                    to: "/concession/list"
                },
                {
                    text: 'update',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/RTP/get/:slug',
        name: 'rtp-update',
        component: () => import('@/views/RTP/RTPupdate.vue'),
        meta: {
            pageTitle: 'Update Rights',
            breadcrumb: [
                {
                    text: 'Right-To-Public',
                    to: "/RTP/list"
                },
                {
                    text: 'Update Right-To-Public',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/concession/list',
        name: 'concession-list',
        component: () => import('@/views/ConcessionDocument/ConcessionList.vue'),
        meta: {
            pageTitle: 'Concession Document',
            breadcrumb: [
                {
                    text: 'Concession Document',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/empanelled/add',
        name: 'empanelled-add',
        component: () => import('@/views/EmpanelledAgencies/EmpanelledAgenciesAdd.vue'),
        meta: {
            pageTitle: 'Add Empanelled Agencies',
            breadcrumb: [
                {
                    text: 'Empanelled Agencies',
                    to: "/empanelled/list"
                },
                {
                    text: 'Add Empanelled Agencies',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/empanelled/get/:slug',
        name: 'empanelled-update',
        component: () => import('@/views/EmpanelledAgencies/EmpanelledAgenciesUpdate.vue'),
        meta: {
            pageTitle: 'Update Empanelled Agencies',
            breadcrumb: [
                {
                    text: 'Empanelled Agencies',
                    to: "/empanelled/list"
                },
                {
                    text: 'Update',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/empanelled/list',
        name: 'empanelled-list',
        component: () => import('@/views/EmpanelledAgencies/EmpanelledList.vue'),
        meta: {
            pageTitle: 'Empanelled Agencies',
            breadcrumb: [
                {
                    text: 'Empanelled Agencies',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/pages/feedback',
        name: 'feedback-page',
        component: () => import('@/views/Pages/Feedback.vue'),
        meta: {
            pageTitle: 'Feedbacks',
            breadcrumb: [
                {
                    text: 'Add',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/feedback/list',
        name: 'feedback-list',
        component: () => import('@/views/Pages/FeedbackList.vue'),
        meta: {
            pageTitle: 'Feedback'
        },
    },
    {
        path: '/RTP/add',
        name: 'rtp-add',
        component: () => import('@/views/RTP/RTPadd.vue'),
        meta: {
            pageTitle: 'Add Rights',
            breadcrumb: [
                {
                    text: 'Right-To-Public',
                    to: "/RTP/list"
                },
                {
                    text: 'Add Right-To-Public',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/RTP/list',
        name: 'rtp-list',
        component: () => import('@/views/RTP/RTPList.vue'),
        meta: {
            pageTitle: 'Right-To-Public',
            breadcrumb: [
                {
                    text: 'Right-To-Public',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/pages/faq',
        name: 'faq-page',
        component: () => import('@/views/Pages/FAQ.vue'),
        meta: {
            pageTitle: 'FAQs',
            breadcrumb: [
                {
                    text: 'FAQ',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/pages/contact-page',
        name: 'contact-page',
        component: () => import('@/views/Pages/ContactUs.vue'),
        meta: {
            pageTitle: 'Contact Us Page',
            breadcrumb: [
                {
                    text: 'contact us',
                    active: true,
                },
            ],
        },   
    },
    {
        path: '/pages/property-details',
        name: 'property-details-page',
        component: () => import('@/views/Pages/PropertyDetails.vue'),
        meta: {
            pageTitle: 'Property Details Page',
            breadcrumb: [
                {
                    text: 'Property Details',
                    active: true,
                },
            ],
        },  
    },
    {
        path: '/review/list',
        name: 'review-list',
        component: () => import('@/views/Reviews/ReviewList.vue'),
        meta: {
            pageTitle: 'Reviews',
            breadcrumb: [
                {
                    text: 'Reviews',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/review/add',
        name: 'review-add',
        component: () => import('@/views/Reviews/Reviewadd.vue'),
        meta: {
            pageTitle: 'Add Review',
            breadcrumb: [
                {
                    text: 'Review',
                    to: "/review/list"
                },
                {
                    text: 'Add',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/review/:slug',
        name: 'review-update',
        component: () => import('@/views/Reviews/ReviewUpdate.vue'),
        meta: {
            pageTitle: 'Update Review',
            breadcrumb: [
                {
                    text: 'Review',
                    to: "/review/list"
                },
                {
                    text: 'Add',
                    active: true,
                },
            ],
        },
    },
]