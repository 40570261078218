export default [{
    path: '/property-type/add',
    name: 'property-type-add',
    component: () => import('@/views/Property-Types/typeAdd.vue'),
    meta: {
        pageTitle: 'Add Property Type',
        breadcrumb: [
            {
                text: 'Add Property Type',
                active: true,
            },
        ],
    },
},
{
    path: '/property-type/get/:slug',
    name: 'property-type-update',
    component: () => import('@/views/Property-Types/typeUpdate.vue'),
    meta: {
        pageTitle: 'Update Property Type',
        breadcrumb: [
            {
                text: 'Update Property Type',
                active: true,
            },
        ],
    },
},
{
    path: '/property-type/list',
    name: 'property-type-list',
    component: () => import('@/views/Property-Types/typeList.vue'),
    meta: {
        pageTitle: 'Property Types',
        breadcrumb: [
            {
                text: 'Property Types',
                active: true,
            },
        ],
    },
},]