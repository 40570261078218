export default [{
    path: '/amenity/add',
    name: 'amenity-add',
    component: () => import('@/views/Amenities/AmenityAdd.vue'),
    meta: {
        pageTitle: 'Add Amenity',
        breadcrumb: [
            {
                text: 'Add Amenity',
                active: true,
            },
        ],
    },
},
{
    path: '/amenity/list',
    name: 'amenity-list',
    component: () => import('@/views/Amenities/AmenityList.vue'),
    meta: {
        pageTitle: 'All Amenities',
        breadcrumb: [
            {
                text: 'All Amenities',
                active: true,
            },
        ],
    },
},
{
    path: '/amenity/get/:slug',
    name: 'amenity-update',
    component: () => import('@/views/Amenities/AmenityUpdate.vue'),
    meta: {
        pageTitle: 'Update Amenity',
        breadcrumb: [
            {
                text: 'Update Amenity',
                active: true,
            },
        ],
    },
},
]