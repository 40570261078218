export default [{
    path: '/gallery/add',
    name: 'gallery-add',
    component: () => import('@/views/Gallery/Upload.vue'),
    meta: {
        pageTitle: 'Upload Media',
        breadcrumb: [
            {
                text: 'Add Media',
                active: true,
            },
        ],
    },
},
{
    path: '/gallery/view',
    name: 'gallery-view',
    component: () => import('@/views/Gallery/View.vue'),
    meta: {
        pageTitle: 'View All Media',
        breadcrumb: [
            {
                text: 'Gallery View',
                active: true,
            },
        ],
    },
}
]