export default [
  {
    path: "/refund/list",
    name: "refund-list",
    component: () => import("@/views/Refund/RefundList.vue"),
    meta: {
      pageTitle: "Transactions",
      breadcrumb: [
        {
          text: "Transactions",
          active: true,
        },
      ],
    },
  },
  {
    path: "/refund/cancel",
    name: "refund-transaction",
    component: () => import("@/views/Refund/Refund.vue"),
    meta: {
      pageTitle: "Initiate Refund",
      breadcrumb: [
        {
          text: "Initiate Refund",
          active: true,
        },
      ],
    },
  },
];
