export default [{
    path: '/attraction/add',
    name: 'attraction-add',
    component: () => import('@/views/Attractions/AttractionAdd.vue'),
    meta: {
        pageTitle: 'Add Attraction',
        breadcrumb: [
            {
                text: 'Add Attraction',
                active: true,
            },
        ],
    },
},
{
    path: '/attraction/get/:slug',
    name: 'attraction-update',
    component: () => import('@/views/Attractions/AttractionUpdate.vue'),
    meta: {
        pageTitle: 'Update Attraction',
        breadcrumb: [
            {
                text: 'Update Attraction',
                active: true,
            },
        ],
    },
},
{
    path: '/attraction/list',
    name: 'attraction-list',
    component: () => import('@/views/Attractions/AttractionList.vue'),
    meta: {
        pageTitle: 'Attractions',
        breadcrumb: [
            {
                text: 'Attraction',
                active: true,
            },
        ],
    },
},]