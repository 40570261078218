export default [
  {
    path: "/report/booking",
    name: "booking-reports",
    component: () => import("@/views/Reports/BookingReport.vue"),
    meta: {
      pageTitle: "Booking Reports",
      breadcrumb: [
        {
          text: "Booking Reports",
          active: true,
        },
      ],
    },
  },
  {
    path: "/report/cancel-booking",
    name: "cancel-booking-reports",
    component: () => import("@/views/Reports/BookingCancelReport.vue"),
    meta: {
      pageTitle: "Cancel Booking Reports",
      breadcrumb: [
        {
          text: "Cancel Booking Reports",
          active: true,
        },
      ],
    },
  },
  {
    path: "/report/e-invoices",
    name: "e-invoices-reports",
    component: () => import("@/views/Reports/E-InvoicesReport.vue"),
    meta: {
      pageTitle: "E-Invoices Reports",
      breadcrumb: [
        {
          text: "E-Invoices Reports",
          active: true,
        },
      ],
    },
  },
];
