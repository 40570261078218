export default [
    {
        path: '/page/tender-page',
        name: 'tender-page',
        component: () => import('@/views/Pages/Tender/TenderPageAdd.vue'),
        meta: {
            pageTitle: 'Tender Archives',
            breadcrumb: [
                {
                    text: 'Home',
                    to: "/"
                },
                {
                    text: 'Tender',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/tender-item/add',
        name: 'tender-item',
        component: () => import('@/views/Pages/Tender/TenderItems/TenderItemsAdd.vue'),
        meta: {
            pageTitle: 'Tender Items',
            breadcrumb: [
                {
                    text: 'Tender Items',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/tender-item/:slug',
        name: 'tender-update',
        component: () => import('@/views/Pages/Tender/TenderItems/TenderItemsUpdate.vue'),
        meta: {
            pageTitle: 'Update Tender',
            breadcrumb: [
                {
                    text: 'All Tenders',
                    to: "/events/list"
                },
                {
                    text: 'Update List',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/tender/list',
        name: 'tender-list',
        component: () => import('@/views/Pages/Tender/TenderItems/TenderList.vue'),
        meta: {
            pageTitle: 'All Tenders',
            breadcrumb: [
                {
                    text: 'Tender List',
                    active: true,
                },
            ],
        },
    },
    // {
    //     path: '/explore-maharashtra/:slug',
    //     name: 'explore-maharashtra-update',
    //     component: () => import('@/views/Experiences/ExperienceUpdate.vue'),
    //     meta: {
    //         pageTitle: 'Experience',
    //         breadcrumb: [
    //             {
    //                 text: 'Explore MH List',
    //                 to: "/explore-maharashtra/list"
    //             },
    //             {
    //                 text: 'Experience Details',
    //                 active: true,
    //             },
    //         ],
    //     },
    // },
    // {
    //     path: '/explore-maharashtra/explore-item/list',
    //     name: 'explore-item-list',
    //     component: () => import('@/views/Experiences/ExperienceItems/ExperienceItemList.vue'),
    //     meta: {
    //         pageTitle: 'Experience Items',
    //         breadcrumb: [
    //             {
    //                 text: 'Experience Items',
    //                 active: true,
    //             },
    //         ],
    //     },
    // },
    // {
    //     path: '/explore-maharashtra/explore-item/add',
    //     name: 'explore-item-add',
    //     component: () => import('@/views/Experiences/ExperienceItems/ExperienceItemAdd.vue'),
    //     meta: {
    //         pageTitle: 'Add Experience Items',
    //         breadcrumb: [
    //             {
    //                 text: 'Add Experience Items',
    //                 active: true,
    //             },
    //         ],
    //     },
    // },
    // {
    //     path: '/experience/experience-item/:slug',
    //     name: 'experience-item-update',
    //     component: () => import('@/views/Experiences/ExperienceItems/ExperienceItemUpdate.vue'),
    //     meta: {
    //         pageTitle: 'Add Experience Items',
    //         breadcrumb: [
    //             {
    //                 text: 'Add Experience Items',
    //                 active: true,
    //             },
    //         ],
    //     },
    // },
]