export default [{
    path: '/theme/add',
    name: 'theme-add',
    component: () => import('@/views/Themes/ThemesAdd.vue'),
    meta: {
        pageTitle: 'Add Theme',
        breadcrumb: [
            {
                text: 'Add Theme',
                active: true,
            },
        ],
    },
},
{
    path: '/theme/get/:slug',
    name: 'theme-update',
    component: () => import('@/views/Themes/ThemesUpdate.vue'),
    meta: {
        pageTitle: 'Update Theme',
        breadcrumb: [
            {
                text: 'Update Theme',
                active: true,
            },
        ],
    },
},
{
    path: '/theme/list',
    name: 'theme-list',
    component: () => import('@/views/Themes/ThemesList.vue'),
    meta: {
        pageTitle: 'All Themes',
        breadcrumb: [
            {
                text: 'All Themes',
                active: true,
            },
        ],
    },
},]