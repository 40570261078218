export default [
    {
        path: '/hotel/add',
        name: 'hotel-add',
        component: () => import('@/views/Hotel/AddHotel.vue'),
        meta: {
            pageTitle: 'Add Hotel',
            breadcrumb: [
                {
                    text: 'Add Hotel',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/hotel/list',
        name: 'hotel-list',
        component: () => import('@/views/Hotel/HotelLists.vue'),
        meta: {
            pageTitle: 'All Hotels',
            breadcrumb: [
                {
                    text: 'All Hotels',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/hotel/:slug',
        name: 'hotel-update',
        component: () => import('@/views/Hotel/UpdateHotel.vue'),
        meta: {
            pageTitle: 'Update Hotels',
            breadcrumb: [
                {
                    text: "Hotels",
                    to: "/hotel/list"
                },
                {
                    text: 'Update Hotels',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/hotel/:hotelID/inventory-data',
        name: 'hotel-inventory-data',
        component: () => import('@/views/Hotel/InventoryDataList.vue'),
        meta: {
            pageTitle: 'Hotel Inventories',
            breadcrumb: [
                {
                    text: 'Hotel Inventory Data',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/hotel/:hotelID/inventory-data/:roomID',
        name: 'hotel-inventory-data-update',
        component: () => import('@/views/Hotel/InventoryData.vue'),
        meta: {
            pageTitle: 'Inventory Data',
            breadcrumb: [
                {
                    text: 'Update Inventory Data',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/hotel/:hotelID/inventory-data/:roomID/rate-plans',
        name: 'hotel-inventory-rate-plans',
        component: () => import('@/views/Hotel/RatePlansList.vue'),
        meta: {
            pageTitle: 'Rate Plans',
            breadcrumb: [
                {
                    text: 'Rate Plans',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/hotel/:hotelID/inventory-data/:roomID/rate-plans/:ratePlanID',
        name: 'hotel-inventory-rate-plan-update',
        component: () => import('@/views/Hotel/RatePlansUpdate.vue'),
        meta: {
            pageTitle: 'Rate Plans',
            breadcrumb: [
                {
                    text: 'Rate Plans',
                    active: true,
                },
            ],
        },
    },

    //Discounts
    {
        path: '/discounts/add',
        name: 'discount-add',
        component: () => import('@/views/Discounts/AddDiscount.vue'),
        meta: {
            pageTitle: 'Add Discount',
            breadcrumb: [
                {
                    text: 'Add Discount',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/discounts/list',
        name: 'discount-list',
        component: () => import('@/views/Discounts/DiscountList.vue'),
        meta: {
            pageTitle: 'All Discounts',
            breadcrumb: [
                {
                    text: 'All Discounts',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/discounts/update/:discountId',
        name: 'discount-update',
        component: () => import('@/views/Discounts/UpdateDiscount.vue'),
        meta: {
            pageTitle: 'Update Discount',
            breadcrumb: [
                {
                    text: 'Update Discount',
                    active: true,
                },
            ],
        },
    },
]